.navbar {
  background: transparent !important;
  z-index: 999;
  box-shadow: none;
  height: 60px !important;
  top: 0; /* Initially, set the top position to 0 */
}
.userWayContainer {
  position: absolute;
  right: 9%;
}
.navbar h1 {
  font-weight: 700;
  margin-left: 60px;
}
.navbar>.container {
  justify-content: center;
}
.navbar-expand-lg .navbar-collapse {
   justify-content: flex-end;
}
.navbar-dark,
.navbar[data-bs-theme=dark] {
  --bs-navbar-toggler-icon-bg: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAAAAXNSR0IArs4c6QAAAItJREFUSEtjZKADYKSDHQyjlpAUyvDg+j9NUY+BkamDgYGBiyQTsCv+xsD4u4Qx49E1kDTCkhnKuQwMDJOoYAHEiP//Yxgz7y1FtaSBgYlBXNGEgeE/FXzC+I3h5f0zjA0M/1AsoZoPsBg0GicEQnc0TkhNfqP5hIQQGy27SAgsNKWj1S9JYUeX4AIA6DpYGq4jXR8AAAAASUVORK5CYII=);
}
.navbar.active {
  background: #0b1237 !important;
}
.contactUs-Nav,
.contactUs-Nav:hover
{
  color: #fff;
  background-color: #ff541c;
  border: 1px solid #ff541c;
}
.brandTagLine, .brandTagLine:hover, .brandTagLine .btn:first-child:active {
  background-color: transparent !important;
  border: none;
  color: #ff541c ;
  font-weight: 600;
}
@media screen and (min-width: 992px) {
  .spl-logo {
    height: 40px;
    margin-left: 70px;
  }
  .brandTagLineContainerMobile {
    display: none;
  }

  .navbar-nav .nav-link:nth-last-child(1),
  .navbar-nav .nav-link:nth-last-child(2),
  .navbar-nav .nav-link:nth-last-child(3) {
    border-right: none;
    padding-right: 0 !important;
  }
  .navbar-dark .navbar-nav .nav-link {
    color: #ffffff;
  }
}
.navbar-brand a {
  text-decoration: none;
}

.navbar-dark .navbar-nav .nav-link:hover {
  color: #f26f24;
}

@media screen and (max-width: 991px) {
  .spl-logo {
    height: 35px;
    margin-left: 70px;
  }
  .dropdown-menu {
    border: 1px solid #ff541c;
    font-size: 13px;
  }
  .dropdown-item:hover, .dropdown-item:active {
    background-color: #FFF !important;
    color: #ff541c;
  }
  .navbar {
    transition: all .3s ease-in;
  }
  .navbarTransition {
    transform: translateY(-100%);
    transition: all .3s ease-in;
  }
  .brandTagLineContainerMobile{
    position: relative;
    z-index: 1;
  }
  .brandTagLine {
    display: none;
  }
  .navbar-collapse {
    background-color: #fff;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
    padding: 1rem;
    text-align: left;
  }
  .main_logo {
    width: 165px;
    margin-top: -10px;
  }
  .brandTagLineMobile {
    margin-top: 4rem;
    display: block;
    margin-left: auto;
    margin-right: auto;
    background-color: transparent;
    border: none;
    color: #ff541c ;
    font-weight: 600;
  }
  .brandTagLineMobile:hover {
    background-color: #ff541c;
    border: none;
  }
  .navbar-dark .navbar-nav .nav-link {
    color: #ff541c;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .main_logo {
    width: 180px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .dropdown-menu {
    margin-top: 20px !important;
    margin-left: -45px;
    text-align: center;
    background: linear-gradient(180deg, #201a71 0%, #300f66 100%);
    font-size: 13px;
  }
  .dropdown-item {
    color: #ffffff;
    background-color: none !important;
  }
  .dropdown-item:active, .dropdown-item:hover {
    background-color: transparent;
    color: #ff541c;
  }
  .navbar-dark .navbar-nav .nav-link {
    font-size: 12px;
    margin-bottom: -20px;
    padding: 0 10px;
  }
  .main_logo {
    width: 120px;
    margin-top: 15px;
  }
  .contactUs-Nav,
  .brandTagLine {
    font-size: 10px;
    margin-top: -8px;
  }
  
  
  .brandTagLine {
    font-size: 11px;
    margin-top: -8px;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1399px) {
  .dropdown-menu {
    margin-top: 20px !important;
    margin-left: -50px;
    text-align: center;
    background: linear-gradient(180deg, #201a71 0%, #300f66 100%);
  }
  .dropdown-item {
    color: #ffffff;
    background-color: none !important;
  }
  .dropdown-item:active, .dropdown-item:hover {
    background-color: transparent;
    color: #ff541c;
  }
  .navbar-dark .navbar-nav .nav-link {
    font-size: 14px;
    margin-bottom: -20px;
    padding: 0 15px;
  }
  .main_logo {
    width: 150px;
    margin-top: 10px;
  }
  .contactUs-Nav,
  .brandTagLine {
    font-size: 12px;
    margin-top: -9px;
  }
}
@media screen and (min-width: 1400px) {
  .dropdown-menu {
    margin-top: 10px !important;
    margin-left: -45px;
    text-align: center;
    background: linear-gradient(180deg, #201a71 0%, #300f66 100%);
  }
  .dropdown-item {
    color: #ffffff;
    background-color: none !important;
  }
  .dropdown-item:active, .dropdown-item:hover {
    background-color: transparent;
    color: #ff541c;
  }
  .navbar-dark .navbar-nav .nav-link {
    font-size: 17px;
    margin-bottom: -10px;
    padding: 0 14px;
  }
  .main_logo {
    width: 170px;
    margin-top: 9px;
  }
  .contactUs-Nav{
    font-size: 13px;
    margin-top: -7px;
  }
   .brandTagLine {
    font-size: 15px;
    margin-top: -7px;
  }
  .contactUs-Nav{
    margin-left: 10px;
  }
}
